<template>
    <div class="main">
        <p><strong>“{{ bundleName }}”用户协议</strong></p>
        <p>更新日期：2022年9月28日</p>
        <p>生效日期：2022年9月28日</p>

        <br><p><strong>导言</strong></p>
        <p><strong>欢迎您使用“{{ bundleName }}”软件及相关服务！</strong></p>
        <p><strong>《“{{ bundleName }}”用户协议》（以下简称“本协议”）是您与“{{ bundleName }}”（下称“我们”）就您下载、安装、注册、登录、使用（以下统称“使用”）“{{ bundleName }}”软件，并获得“{{ bundleName }}”软件提供的相关服务所订立的协议。</strong></p>
        <p>为了更好地为您提供服务，请您在开始使用“{{ bundleName }}”软件及相关服务之前，认真阅读并充分理解本协议的内容，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款及法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。</p>
        <p>如您不同意本协议，将导致我们无法为您提供完整的产品和服务，您也可以选择停止使用。如您自主选择同意或使用“{{ bundleName }}”软件及相关服务，则视为您已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与“{{ bundleName }}”软件及相关服务相关的协议和规则（包括但不限于《“{{ bundleName }}”隐私政策》和所有“{{ bundleName }}”已经发布或未来可能发布的各类规则、公告或通知）的约束。</p>
        <p>如果您是未满18周岁的未成年人，请在监护人的陪同下阅读并理解本协议及上述其他协议。特别地，如果你是未满14周岁的未成年人，还应请您的监护人仔细阅读《“{{ bundleName }}”隐私政策》中关于未成年人的相关条款，只有在征得您的监护人的同意后，您方可使用“{{ bundleName }}”软件及相关服务。</p>
        <p><strong>特别提示：</strong></p>
        <p>您基于本协议及您使用“{{ bundleName }}”的相关服务而授权给“{{ bundleName }}”的相关内容（包括您上传的图片、分享、发布的信息、或展示地理位置以及提供的个人信息等），“{{ bundleName }}”仅限用于为您提供拍摄、分享展示和评论的服务，相关内容将严格按相关法律法规的规定保留在“{{ bundleName }}”上，除非为了改善“{{ bundleName }}”为您提供的服务或另行取得您的再次同意，否则“{{ bundleName }}”不会以任何其他形式或目的使用上述内容。</p>
        <p>同时，您可以依据“{{ bundleName }}”平台规则修改、撤回或删除上述内容，在您撤回或删除上述内容后，“{{ bundleName }}”将依据相关法律法规的规定在服务器上删除相关内容。</p>
        <p><strong>1. 服务内容</strong></p>
        <p>“{{ bundleName }}”是一个社交网络，用户可在收到“{{ bundleName }}”与其他用户视频、聊天，以及给“{{ bundleName }}”用户分享展示的照片和动态（以下简称“本服务”）。</p>
        <p><strong>2. 用户个人信息保护</strong></p>
        <p>我们与您一同致力于您个人信息的保护。</p>
        <p>保护用户个人信息是我们的基本原则之一，在使用“{{ bundleName }}”软件及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于您的电话号码、设备信息等），以便我们向您提供更好的服务和相应的技术支持。我们将依法保护您浏览、修改、删除相关个人信息以及撤回授权的权利，并将运用加密技术、匿名化处理等其他与“{{ bundleName }}”软件及相关服务相匹配的技术措施及其他安全措施保护您的个人信息。</p>
        <p>更多关于您个人信息保护的内容，请参看《“{{ bundleName }}”隐私政策》。</p>
        <p><strong>3. 关于“帐号”</strong></p>
        <p>3.1 您在使用本服务前需要注册一个{{ bundleName }}帐号，{{ bundleName }}帐号可以通过手机号码进行注册，“{{ bundleName }}”软件及相关服务为您提供了注册通道，在注册成功后您有权选择合法的字符组合作为自己的帐号，您在注册时提供的手机号码是您以注册用户身份登录并使用“{{ bundleName }}”软件及相关服务的凭证。</p>
        <p>3.2 帐号注销：在需要终止使用“{{ bundleName }}”帐号服务时，符合以下条件的，您可以申请注销您的“{{ bundleName }}”帐号：</p>
        <p>（1）您仅能申请注销您本人的帐号，并依照“{{ bundleName }}”的流程进行注销；</p>
        <p>（2）您仍应对您在注销帐号前且使用“{{ bundleName }}”服务期间的行为承担相应责任；</p>
        <p>（3）注销成功后，帐号记录、功能等将无法恢复或提供。</p>
        <p>（4）您的帐号注销成功后，14日内无法再注册新的“{{ bundleName }}”帐号。</p>
        <p>如您需要注销您的“{{ bundleName }}”帐号，请打开{{ bundleName }}客户端，在您的用户个人页面中依次点击主界面右下角“我的”-“设置”-“注销帐户”来注销帐号。您确认注销账号是不可恢复的操作，您应自行备份与账号相关的信息和数据，您确认操作之前与账号相关的所有服务均已进行妥善处理。您确认并同意注销账号后并不代表本账号注销前的账号行为和相关责任得到豁免或减轻，如在注销期间，您的账号被他人投诉、被国家机关调查或者正处于诉讼、仲裁程序中，“{{ bundleName }}”有权自行终止用户的账号注销。</p>
        <p>3.3 <strong>您理解并承诺，您所设置的帐号不得违反国家法律法规及“{{ bundleName }}”的相关规则，您的帐号名称、头像等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等或采取其他足以让人引起混淆的方式）开设帐号，不得恶意注册“{{ bundleName }}”帐号（包括但不限于频繁注册、批量注册帐号等行为）。您在帐号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。我们有权对您提交的注册信息进行审核。</strong></p>
        <p>3.4 您在“{{ bundleName }}”中的注册帐号仅限于您本人使用，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该帐号。如果我们发现或者有合理理由认为使用者并非帐号初始注册人，为保障帐号安全，我们有权立即暂停或终止向该注册帐号提供服务，并有权永久禁用该帐号。</p>
        <p>3.5 您有责任维护个人帐号、密码的安全性与保密性，并对您以注册帐号名义所从事的活动承担全部法律责任，包括但不限于您在“{{ bundleName }}”软件及相关服务上进行的任何数据修改、言论发表等操作行为可能引起的一切法律责任。您应高度重视对帐号与密码的保密，在任何情况下不向他人透露帐号及密码。若发现他人未经许可使用您的帐号或发生其他任何安全漏洞问题时，您应当立即通知我们。</p>
        <p>3.6 当您完成“{{ bundleName }}”的帐号注册、登录后，您可随时浏览、修改自己提交的个人身份信息。您理解并同意，出于安全性您也可以按第3.2条的约定申请注销帐号，我们会依照您的要求删除有关您帐号的一切信息，法律法规另有规定的除外。</p>
        <p>3.7 您理解并同意，为了充分使用帐号资源，如您在注册后未及时进行初次登录使用或连续超过二十四个月未登录帐号并使用，公司有权收回您的帐号。如您的帐号被收回，您可能无法通过您此前持有的帐号登录并使用“{{ bundleName }}”软件及相关服务，您该帐号下保存的任何个性化设置和使用记录将无法恢复。在收回您的帐号之前，公司将以适当的方式向您作出提示，如您在收到相关提示后一定期限内仍未登录、使用帐号，公司将进行帐号收回。</p>
        <p>3.8 如您的帐号被收回，我们会删除有关您帐号的一切信息，法律法规另有规定的除外。您可以通过注册新的帐号登录、使用“{{ bundleName }}”软件及相关服务。您注册新帐号并登录、使用的行为仍受到本协议相关条款的约束。</p>
        <p><strong>4. 您的必要授权</strong></p>
        <p>4.1 在您使用“{{ bundleName }}”提供的服务时需要提供一些必要的授权，例如：您需要通过手机号码注册帐号，同意并授权我们将您在“{{ bundleName }}”上发布的内容（定义详见本协议第5条），包括但不限于将您在“{{ bundleName }}”上发布的图片、视频、文字、展示的地址位置等内容通过信息网络传播、展示给其他用户。</p>
        <p>4.2 在您权益受到侵犯并且导致“{{ bundleName }}”权益受损情况下，与“{{ bundleName }}”作为一致行动人，授予“{{ bundleName }}”代表您，针对进行该侵犯行为的任何第三方，提出警告、投诉、发起或参与行政执法程序或民事、刑事司法程序，或谈判和解，并且您同意在“{{ bundleName }}”认为必要的情况下共同参与该维权行动。</p>
        <p><strong>5. 内容发布规范</strong></p>
        <p>5.1 “{{ bundleName }}”致力使发布信息、互动交流、评论成为文明、理性、友善、高质量的意见交流。在推动发布信息、互动交流、评论业务发展的同时，不断加强相应的信息安全管理能力，完善发布信息、互动交流、评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。</p>
        <p>5.2 您应对使用“{{ bundleName }}”发布的内容的真实性、合法性负责。本协议所述内容是指您使用本软件及服务过程中所制作、复制、发布、传播的任何内容，包括但不限于{{ bundleName }}帐号的头像、昵称、用户说明、简介等个人信息，或发送的文字、语音、图片、视频、表情等信息、评论、相关链接页面 ，以及其他使用本软件及服务所产生的内容。您同意严格遵守相关法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实性等“七条底线”要求以及遵守“{{ bundleName }}”平台相关规则，否则公司有权立即采取相应处理措施。您同意并承诺不得直接或间接发布如下法律、法规和政策禁止的信息：</p>
        <p>（1） 反对宪法确定的基本原则的；</p>
        <p>（2） 危害国家安全，泄露国家秘密的；</p>
        <p>（3） 颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；</p>
        <p>（4） 损害国家荣誉和利益的；</p>
        <p>（5） 宣扬恐怖主义、极端主义的；</p>
        <p>（6） 宣扬民族仇恨、民族歧视，破坏民族团结的；</p>
        <p>（7） 煽动地域歧视、地域仇恨的；</p>
        <p>（8） 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
        <p>（9） 编造、散布谣言、虚假信息，扰乱经济秩序和社会秩序、破坏社会稳定的；</p>
        <p>（10） 散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
        <p>（11） 煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
        <p>（12） 危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</p>
        <p>（13） 侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p>（14） 对他人进行暴力恐吓、威胁，实施人肉搜索的；</p>
        <p>（15） 涉及他人隐私、个人信息或资料的；</p>
        <p>（16） 散布污言秽语，损害社会公序良俗的；</p>
        <p>（17） 侵犯他人隐私权、名誉权、肖像权、知识产权、商业秘密等合法权益内容的；</p>
        <p>（18） 不正当竞争信息，其表现可能包括但不限于发布篡改、增减、删除权利标识的内容；发布未经授权方授权的内容等。</p>
        <p>（19） 散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；</p>
        <p>（20） 所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</p>
        <p>（21） 侵害未成年人合法权益或者损害未成年人身心健康的；</p>
        <p>（22） 未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</p>
        <p>（23） 虚假广告、虚假中奖信息等欺诈信息；</p>
        <p>（24） 包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的，包括但不限于以下情形：</p>
        <p> i. 任何暴力和/或自残行为内容；</p>
        <p> ii. 任何威胁生命健康、利用刀具等危险器械表演的危及自身或他人人身及/或财产权利的内容；</p>
        <p> iii.怂恿、诱导他人参与可能会造成人身伤害或导致死亡的危险或违法活动的内容；</p>
        <p>（25） 其他含有违反法律法规、政策及公序良俗、干扰“{{ bundleName }}”正常运营或侵犯其他用户或第三方合法权益内容的信息。</p>
        <p>5.3 <strong>若您违反本协议和/或“{{ bundleName }}”平台的其他规则，您同意“{{ bundleName }}”有权根据自身的判断或法律与政策的需要，自主决定并可在不预先通知您的情况下，全部或者部分地选择：（1）采取技术手段删除、屏蔽或断开相关用户内容的链接；（2）暂停或终止对您的软件使用许可并停止相应技术服务，采取包括但不限于限制、冻结或终止“{{ bundleName }}”帐号使用措施；（3）诉诸行政执法机构或者司法机关，以追究相应法律责任。</strong></p>
        <p>5.4 “{{ bundleName }}”设立公众投诉、举报平台，您可按照公司公示的投诉举报制度向公司投诉、举报各类违法违规行为、违法传播活动、违法有害信息等内容，公司将及时受理和处理您的投诉举报，以共同营造风清气正的网络空间。</p>
        <p><strong>6. 违约处理</strong></p>
        <p>6.1 <strong>针对您违反本协议或其他服务条款的行为，我们有权视情况采取预先警示、拒绝发布、立即停止传输信息、音频、视频、音视频等内容、短期禁止发言、限制帐号部分或者全部功能直至终止提供服务、永久关闭帐号等措施。我们有权公告处理结果，且有权根据实际情况决定是否恢复相关帐号的使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为，我们将保存有关记录，并有权依法向有关主管部门报告、配合有关主管部门调查、向公安机关报案等。对已删除内容我们有权不予恢复。</strong></p>
        <p>6.2 <strong>因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行处理并承担可能因此产生的法律责任，包括但不限于赔偿“{{ bundleName }}”与合作公司、关联公司，并使之免受损害。</strong></p>
        <p>6.3 “{{ bundleName }}”尊重并保护用户及他人的知识产权、名誉权、姓名权、肖像权及隐私权等合法权益。您使用“{{ bundleName }}”软件及相关服务时上传的文字、图片、视频、照片、音频、链接等不应侵犯任何第三方的知识产权、名誉权、姓名权、肖像权、隐私权等权利及合法权益，否则，我们有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行处理并承担可能因此产生的全部法律责任；如因您的侵权行为导致“{{ bundleName }}”及其关联方遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿“{{ bundleName }}”及其关联方遭受的全部损失。</p>
        <p>6.4 除非“{{ bundleName }}”书面许可，您不得从事下列任一行为：</p>
        <p>(1) 删除软件副本上关于著作权的信息；</p>
        <p>(2) 对软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现软件的源代码；</p>
        <p>(3) 对“{{ bundleName }}”及其关联公司拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</p>
        <p>(4) 对软件或者软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经“{{ bundleName }}”及其授权的第三方工具/服务接入软件和相关系统；</p>
        <p>(5) 通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</p>
        <p>(6) 通过非“{{ bundleName }}”开发、授权的第三方软件、插件、外挂、系统，登录或使用“{{ bundleName }}”软件及服务，或制作、发布、传播非“{{ bundleName }}”开发、授权的第三方软件、插件、外挂、系统。</p>
        <p><strong>7. 数据存储</strong></p>
        <p><strong>“{{ bundleName }}”不对您在本服务中相关数据的删除或储存失败负责。在符合相关法律法规的前提下，</strong>“{{ bundleName }}”将根据实际情况决定您在本服务中数据的最长储存期限。您可根据自己的需要自行备份本服务中的相关数据。<strong>本服务停止、终止后，“{{ bundleName }}”将依据法律法规的相关规定从服务器上永久地删除您的数据，在“{{ bundleName }}”删除您的数据后，我们将无法向您返还任何数据。</strong></p>
        <p><strong>8. 投诉举报处理</strong></p>
        <p>“{{ bundleName }}”倡议您共同参与维护及监督“{{ bundleName }}”使用环境的合规与生态健康。无论是“{{ bundleName }}”主动发现用户的不当、违法行为，或者是“{{ bundleName }}”根据举报、投诉并初步查实用户存在不当、违法行为，“{{ bundleName }}”均有权立即在无事先通知的情况下采取必要的措施以制止该行为的继续进行。</p>
        <p><strong>9. 风险承担</strong></p>
        <p><strong>“{{ bundleName }}”仅为用户提供内容发布、信息分享、传送及获取的平台，您必须为自己注册帐号下的行为负责，包括您所传送的内容以及由此产生的后果。您应对“{{ bundleName }}”及本服务中的内容自行加以判断，并承担因使用内容而引起的风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。因业务发展需要，“{{ bundleName }}”有权对本服务的全部或部分服务内容变更、暂停、终止或撤销，您需承担此风险。</strong></p>
        <p><strong>10. 法律责任</strong></p>
        <p><strong>如果“{{ bundleName }}”发现或收到他人举报或投诉您违反本协议约定的，“{{ bundleName }}”有权对相关内容，包括但不限于对用户资料、发布内容进行审查、删除，并视情节轻重对违规帐号处以包括但不限于警告、帐号封禁 、功能封禁的处罚，且通知您处理结果。</strong>您理解并同意，“{{ bundleName }}”有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应承担由此而产生的相应法律责任。</p>
        <p><strong>11. 知识产权声明</strong></p>
        <p>11.1 “{{ bundleName }}”本身自带的所有版本、技术、程序、道具和所有的信息内容（“{{ bundleName }}”信息内容，包括但不限于产品功能、用户界面、操作步骤、商标、专利、计算机软件作品、网页、文字、图片、音频、视频、图表等）的知识产权均归“{{ bundleName }}”、 “{{ bundleName }}”许可方所有，“{{ bundleName }}”对您的软件使用许可在任何情况下均不应视为向您转让其知识产权的部分或者全部。</p>
        <p>11.2 您在使用“{{ bundleName }}”时注册的帐号、昵称以及发表上传的文字、图片、视频、音频等各种形式的内容和信息均由您原创或已获合法授权（且含转授权），此部分信息的知识产权归您所有，责任由您承担。您的发表、上传行为视为对“{{ bundleName }}”的授权，您理解并同意授予“{{ bundleName }}”及其关联公司全球范围内免费、非独家、永久、可转授权和可再许可的权利，包括但不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及《著作权法》规定的由著作权人享有的其他著作财产权利及邻接权利。“{{ bundleName }}”可自行选择是否使用以及使用方式，包括但不限于将前述信息在“{{ bundleName }}”平台上使用与传播，将上述信息再次编辑后使用，以及由“{{ bundleName }}”授权给合作方使用、编辑与传播等。为避免疑义，您理解并同意，上述授予的权利包括使用、复制和展示您拥有或被许可使用并植入内容和信息中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记（如有）以及任何其他品牌、营销或推广资产、物料、素材等的权利和许可。</p>
        <p><strong>12. 不可抗力与其他免责事由</strong></p>
        <p>“{{ bundleName }}”在使用中会受到包括但不限于如下不可抗力或者其他因素的影响：政治原因、社会环境、自然灾害、经济危机、计算机病毒或黑客攻击、服务器或系统不稳定、您所在的特殊位置、任何技术能力、网络质量以及通信线路、计算机故障等（以下统称“免责事由”）。<strong>若出现上述免责事由情况时，“{{ bundleName }}”将尽努力在第一时间进行修复或者及时补救，给您造成损失的，“{{ bundleName }}”在法律与政策允许的范围内免责。您理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的您或第三方的任何损失，“{{ bundleName }}”不承担任何责任。您理解并确认，“{{ bundleName }}”需要定期或不定期地对“{{ bundleName }}”平台或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，“{{ bundleName }}”将无法为此承担任何责任，但“{{ bundleName }}”应事先进行通告。在任何情况下，“{{ bundleName }}”均不对任何间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因您使用本服务而遭受的利润损失，承担责任（即使“{{ bundleName }}”已被告知该等损失的可能性亦然）。尽管本协议中可能含有相悖的规定，“{{ bundleName }}”对您承担的全部责任，无论因何原因或何种行为方式，始终不超过您因使用本服务而支付给“{{ bundleName }}”的费用(如有)。</strong></p>
        <p><strong>13. 服务的变更、中断和终止</strong></p>
        <p>13.1 您理解并同意，我们提供的“{{ bundleName }}”软件及相关服务是按照现有技术和条件所能达到的现状提供的。我们会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，我们不能随时或始终预见和防范法律、技术以及其他风险，包括但不限于不可抗力、网络原因、第三方服务瑕疵、第三方网站等原因可能导致的服务中断、不能正常使用“{{ bundleName }}”软件及相关服务以及其他的损失和风险。</p>
        <p>13.2 您理解并同意，我们为了整体服务运营、平台运营安全的需要，有权视具体情况决定服务/功能的设置及其范围修改、中断、中止或终止“{{ bundleName }}”软件及相关服务。</p>
        <p>13.3 除其他协议另有规定外，“{{ bundleName }}”不对相应变更、中断或终止承担任何责任，您应妥善做好数据备份与转移工作。</p>
        <p><strong>14. 适用法律与纠纷管辖</strong></p>
        <p><strong>本协议成立、生效、履行、解释及纠纷解决，均适用中华人民共和国大陆地区法律（不包括冲突法）。本协议签订地为中华人民共和国江西省赣州市赣县区。若在您与“{{ bundleName }}”之间发生的任何纠纷或争议，双方同意提交本协议签订地江西省赣州市赣县区有管辖权的人民法院诉讼解决。</strong></p>
        <p><strong>15. 其他</strong></p>
        <p>由于互联网高速发展，您与“{{ bundleName }}”签署的本协议列明的条款可能并不能完整罗列并覆盖您与“{{ bundleName }}”所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，隐私政策、平台行为规范及各类规则、公告或通知等均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用“{{ bundleName }}”平台服务，视为您同意上述补充协议。“{{ bundleName }}”可能根据法律与政策要求，或自身对商业等要素的判断，随时更新本协议及补充协议，届时我们将会通过网页公示或等方式告知您，您也可在“{{ bundleName }}”内查阅最新版协议条款；若您无法接受本协议未来更新版本的全部或者部分条款，您可以随时停止使用“{{ bundleName }}”提供的服务，您继续使用“{{ bundleName }}”提供的服务将被视为接受修改后的协议。</p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            bundle: '',
            bundleName: '喂喂',
        }
    },
    mounted() {
        this.bundle = this.$route.params['bundle']
        if (this.bundle == 'anhao') {
            this.bundleName = '暗号'
        }
        if (this.bundle == 'jianliao') {
            this.bundleName = '简聊'
        }
        if (this.bundle == 'weiwei') {
            this.bundleName = '喂喂'
        }
        if (this.bundle == 'mangqu') {
            this.bundleName = '盲趣'
        }
    }
}
</script>

<style scoped>
.main {
    /* width: 375px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

strong {
    font-weight: bold;
    font-size: 10px;
}

p {
    word-break:break-all;
    /* text-indent:4em; */
    font-size: 10px;
    margin-top: 10px;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px;
}

table td, table th {
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
}

table th {
    width: 20%;
    vertical-align: top;
}

tbody {
    border-bottom: 1px solid #000;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}

table td, table td a {
    table-layout: fixed;
    word-break: break-all;
    word-wrap: break-word;
}

</style>